/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


    function balanceCarouselTestimonials() {
 
      maxContentHeight = 0;
      $( "#carousel-testimonials .inner-content .text .inner-text" ).each(function( index ) {
        if(jQuery(this).height() > maxContentHeight) {maxContentHeight = jQuery(this).height();}
      });

      jQuery('#carousel-testimonials .inner-content .text').height(maxContentHeight);
      jQuery('#carousel-testimonials .image').height(jQuery('#carousel-testimonials .content').height());

      maxContentHeight = 0;
      $( "#carousel-news .inner-content .text" ).each(function( index ) {
        if(jQuery(this).height() > maxContentHeight) {maxContentHeight = jQuery(this).height();}
      });

      jQuery('#carousel-news .inner-content .text').height(maxContentHeight);

    }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $( ".js-balance" ).each(function( index ) {

          var balanceClasses = $(this).attr('data-balance');
          if(balanceClasses != '') {
            balanceClassesAr = balanceClasses.split(',');
          }

          for(i = 0; i < balanceClassesAr.length; i++) {   
            
          }
          console.log(balanceClassesAr);
          
        });

//menu-desktop
//menu-item
//menu-item-has-children


      $( ".menu-desktop .menu-item.menu-item-has-children>a" ).mouseover(function() {
        relatedMenu = $(this).parent().find('.sub-menu');
        $(relatedMenu).clearQueue();
        $(relatedMenu).fadeIn();        
      })

      $( ".menu-desktop .menu-item.menu-item-has-children" ).mouseleave(function() {
        relatedMenu = $(this).parent().find('.sub-menu');
        $(relatedMenu).clearQueue();
        $(relatedMenu).fadeOut();        
      })

        // Mobile menu
        $( "body" ).on( "click", ".responsive-menu .menu-item-has-children>a", function() {
         
          if( $(this).parent().find('.sub-menu').css('display') == 'block') {
             $(this).parent().find('.sub-menu').slideUp();
             $(this).parent().removeClass('sub-menu-open');
          } else {
            $(this).parent().find('.sub-menu').slideDown();
            $(this).parent().addClass('sub-menu-open');
          }

          console.log('a');
         event.preventDefault();
        });

        // Burger - mobile menu show/hide
        $( "body" ).on( "click", ".burger-btn", function() {
        
          if( $('.responsive-menu').css('display') == 'block' ) {
            $('.responsive-menu').fadeOut();
          } else {
            $('.responsive-menu').fadeIn();
          }

        });

        // General gallery
        $('.carousel-gallery .owl-carousel').owlCarousel({
          items: 1,
          autoplay: true,
          autoplaySpeed: 7000,
          loop: true,
          nav: true,
          dots: false
        });

        $( "body" ).on( "click", ".accordion-trigger", function() {

          if( $(this).parent().find('.accordion-body').css('display') == 'block' ) {
            $(this).parent().find('.accordion-body').slideUp(

              function() {
                $(this).find('iframe').css({'height':'0px'});
              }

            );
            $(this).removeClass('active');
          } else {
            $(this).parent().find('.accordion-body').slideDown(
                function() {
                  scaleIframeEl(this);
                }
              );
            $(this).addClass('active');
            
          }
        });


        // Scales iframe video appropriately
        function scaleIframe() {

          var newWidth = $fluidEl.width();

          $allVideos.each(function() {

            var newWidth = $(this).parent().width();

            var $el = $(this);
            var newHeight = newWidth * $el.data('aspectRatio');
            $el
              .width(newWidth)
              .height(0);

            $el.animate({'height':newHeight + 'px'});

          });

        }

        // Scales iframe video appropriately
        function scaleIframeEl(iframeParent) {

          var newWidth = $fluidEl.width();

          var $currentElement = $(iframeParent).find('iframe');

          $currentElement.each(function() {

            var newWidth = $(this).parent().width();

            var $el = $(this);
            var newHeight = newWidth * $el.data('aspectRatio');
            $el
              .width(newWidth)
              .height(0);

            $el.animate({'height':newHeight + 'px'});

          });

  

        }

        // Find all YouTube videos
        var $allVideos = $("iframe");

        $fluidEl = $("body");

        $allVideos.each(function() {

          $(this)
            .data('aspectRatio', this.height / this.width)

            .removeAttr('height')
            .removeAttr('width');

        });


        $(window).resize(function() {

          scaleIframe();

        }).resize();










      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('#carousel-hero .owl-carousel').owlCarousel({
          items: 1,
          animateOut: 'fadeOut',
          autoplay: true,
          autoplaySpeed: 7000,
          loop: true,
          nav: false,
          dots: true
        });

        // 
        $('#carousel-testimonials .owl-carousel').owlCarousel({
          items: 1,
          autoplay: true,
          autoplaySpeed: 7000,
          loop: true,
          nav: true,
          dots: false
        });

        $('#carousel-news .owl-carousel').owlCarousel({
          responsive : {
              0 : {
                items: 1
              },
              992 : {
                items: 3
              }
          },
          navText:[''],

          autoplay: true,
          autoplaySpeed: 6000,
          loop: true,
          nav: true,
          dots: false
        });

        clearTimeout(resizeComplete);
        resizeComplete = setTimeout(balanceCarouselTestimonials,500);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  var resizeComplete;

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
      resize: function() {

        clearTimeout(resizeComplete);
        resizeComplete = setTimeout(balanceCarouselTestimonials,500);

      },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).resize(UTIL.resize);

})(jQuery); // Fully reference jQuery after this point.
